.cart_details {
  padding: 30px 0;
  // background-color: #71859e0f;
  position: relative;
  .pay_wrapper {
    position: sticky;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 20px;
    // background-color: #fff;
    .date {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: flex-start;
      font-weight: 600;
      font-size: 22px;
      line-height: 26px;
      color: #000000;
      align-items: center;
      span {
        color: #9e0c22;
      }
    }
    .goto_pay {
      padding: 15px 20px;
      width: calc(100% - 30px);
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
.addoncontr {
  height: 50vh;
  overflow-y: scroll;

}
.fnt {
  font-weight: bolder;
}
.pec {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  background-color: #03b162;
}
.inpt {
  cursor: pointer;
}
.clrstr {
  color: #9e0c22;
}
.matching {
  background-color: #9e0c22 !important;
  color: white !important;
}
.matching1 {
  background-color: white !important;
  color: white !important;
}
@media screen and (max-width: 1240px) {
  .cart_heading {
    padding: 140px 0 30px;
  }
}
@media screen and (max-width: 991px) {
  .cart_heading {
    padding: 100px 0 30px;
  }
}
@media screen and (max-width: 560px) {
  .cart_heading {
    .cart_heading_content {
      flex-direction: column;
      align-items: flex-start;
      .action {
        margin-top: 15px;
      }
    }
  }
  .cart_details {
    .pay_wrapper {
      padding: 10px;
      background-color: #f5f5f5;
      .date {
        font-size: 16px;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 10px;
      }
      .goto_pay {
        padding: 10px 20px;
        margin: 0;
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 560px) {
  .details_wrapper_page {
    .view_cart {
      .price {
        font-size: 16px;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
      .goto_cart {
        width: 100%;
        padding: 10px;
      }
      .view_cart {
        padding: 10px 20px;
      }
    }
  }
}

.cart_item_box {
  border: 1px solid rgba(113, 133, 158, 0.2);
  border-radius: 5px;
  background-color: #fff;
  margin-bottom: 15px;
  .item_title {
    padding: 20px 25px;
    font-weight: 700;
    font-size: 20px;
    line-height: 127.34%;
    color: #000000;
  }
  .item_content {
    padding: 15px 25px;
    background-color: #71859e15;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    .left {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .left_img {
        height: 60px;
        width: 100px;
        overflow: hidden;
        border-radius: 5px;
        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
      .texts {
        margin-left: 10px;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        h4 {
          font-size: 18px;
          line-height: 21px;
          display: flex;
          align-items: center;
          color: #000000;
          margin: 0;
          margin-bottom: 10px;
        }
        p {
          font-weight: 500;
          font-size: 18px;
          line-height: 21px;
          display: flex;
          align-items: center;
          color: #71859e;
          margin: 0;
        }
      }
    }

    .wrong_price {
      font-weight: 400;
      font-size: 18px;
      // line-height: 21px;
      text-decoration-line: line-through;
      color: #71859e;
    }
    .real_price {
      font-weight: 700;
      font-size: 22px;
      // line-height: 26px;
      text-align: right;
      // margin: 0 20px;
      color: #9e0c22;
    }
  }
}

@media screen and (max-width: 560px) {
  .cart_item_box {
    .item_content {
      flex-direction: column;
      .left {
        padding-bottom: 15px;
      }
      .right {
        width: 100%;
        padding-top: 10px;
        border-top: 1px solid #00000010;
      }
    }
  }
}

.cart_heading {
  padding: 100px 0px 30px;
  background-color: rgb(8 180 27 / 5%);
  .cart_heading_content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .text {
      h3 {
        font-weight: 700;
        font-size: 26px;
        line-height: 31px;
        color: #000000;
        margin: 0;
      }
      p {
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        color: #000000;
        margin: 0;
        margin-top: 5px;
      }
    }
    .action {
      .button1 {
        background: #000000;
        padding: 12px 20px;
        border-radius: 5px;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        text-transform: capitalize;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
          fill: #fff;
          margin-right: 10px;
        }
      }
    }
  }
}
.jf_box {
  width: 100%;
  padding: 15px;
  border: 1px solid #00000020;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}
.slider {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.code {
  color: black;
  font-weight: bold;
  border-style: dashed;
  border-color: black;
}
.code1 {
  font-weight: bold;
  font-size: 25px;
}
.slides-container.code:nth-child(2n) {
  background-color: rgb(166, 106, 21) !important;
}
.slides-container {
  display: flex;
  overflow: hidden;
}
@media (min-width: 992px) and (max-width: 1366px) {
  .cart_heading {
    padding: 140px 0 30px;
  }
}

.promo {
  width: 100%;
  background: #ffffff;
  border-radius: 5px;
  padding: 20px;
  .title {
    font-weight: 700;
    font-size: 22px;
    line-height: 26px;
    color: #000000;
    margin-bottom: 25px;
  }
  input {
    background-color: #f8f9fa !important;
  }
}
.react-calendar__navigation button {
  color: black;
}
.react-calendar button {
  color: black;
}
.selecteddate {
  background-color: #9e0c22;
  color: white;
}
