@import url("https://fonts.googleapis.com/css2?family=Montserrat&family=Poppins:wght@100;200;300&family=Roboto&display=swap");

.bg {
  background-color: black;
}
.boldt1 {
  font-weight: bolder !important;
}
.bgimg {
  min-height: 80vh;
  background-image: url("../../public/assests/bghe.jpg");
  background-repeat: no-repeat;
  max-width: 100%;

min-width: fit-content; /* Standard */

  background-size: cover;
}
.navbg {
  box-shadow: 7px 7px 7px #b4082242;
  position: sticky;
  top: 0%;
  z-index: 100;
}
.bordr {
  display: inline-block;
  padding: 10px;
  border-right: 2px solid #b40821;
  margin-right: 5px;
  line-height: 0;
}
.bordr45 {
  display: inline-block;
  padding: 7px;
  border-right: 2px solid white;
  margin-right: 5px;
  line-height: 0;
}
.hrline {
  border-bottom: 2px solid #b40821;
}

.fnt,
p,
span,
h1,
h2,
h3,
h4,
h5,
h6,
a,
.sty {
  font-family: "Montserrat", sans-serif;
  font-family: "Poppins", sans-serif;
  font-family: "Roboto", sans-serif;
}
.fnt14 {
  font-size: 12px;
}
.fnt16 {
  font-size: 18px !important;
  font-weight: bolder !important;
}
.clrrd {
  color: #b40821 !important;
}
.clrrdd {
  font-weight: bolder;
  font-size: 17px;
}
.clr {
  color: #b40821 !important;
  font-weight: 300;
  font-size: 20px;
}
.hd {
  height: 30vh;
}
.cursor {
  cursor: pointer;
}

.brd {
  border-radius: 10px;
}
.fnt12 {
  font-size: 15px;
}

.fsd {
  font-size: 18px;
}
.fnt15 {
  font-size: 13px;
}
.selct {
  height: 15px;
  overflow-y: scroll;
}
.ps {
  position: absolute;
  top: 26%;
  right: 32%;
}
.imgbr {
  border-radius: 100px;
}
.custom-dropdown {
  pointer-events: none;
}

.custom-dropdown:hover {
  background-color: inherit;
  cursor: not-allowed;
}
.custom-dropdown-toggle::before {
  display: none !important;
}

/* .slider-item {
  padding: 0 px;
} */

.slick-sliders {
  width: auto !important;
}
.slick-listsd {
  display: flex;
  justify-content: space-around !important;
}
.slick-dots {
  position: absolute;
  bottom: 10px;
  list-style: none;
  display: flex !important;
  justify-content: center;
}
.subimg1 {
  border: 1px solid white;
  border-left: 4px solid #940a1e;
  border-top: 4px solid #b40821;

  border-radius: 100%;
}
.subimg {
  border-radius: 100%;
  border: 1px solid rgba(0, 0, 0, 0.541);
}
.slick-dots li button {
  font-size: 0;
  width: 10px;
  height: 0px;
  border-radius: 50%;
  border: 1px solid #000;
  background-color: #fff;
}
.subimg3 {
  border-top-right-radius: 30px;
  border-bottom-left-radius: 30px;
}
.brclr {
  border: 1px solid #b40821;
  border-radius: 40px;
}
.brclr1 {
  background-color: rgba(186, 144, 161, 0.386);
}
.brclr1,
.brclr2 {
  border-top-right-radius: 40px;
  border-bottom-left-radius: 40px;
}
.brclr2 {
  border: 1px solid #b40821;
}
.slick-dots li.slick-active button {
  background-color: #000;
}
.crdbor1 {
  /* border-bottom-right-radius: 50px !important; */
  border-top-right-radius: 50px !important;
  border-bottom-left-radius: 35px !important;
  border-right: 1px solid #eae1e2 !important;
}
.crdbor {
  border-bottom-right-radius: 50px !important;
  border-top-right-radius: 50px !important;
  border-right: 5px solid #940a1e !important;
}
.clr2 {
  background-color: #9e0c22;
}
.clr3 {
  color: white;
}
.btnd {
  border-radius: 20px;
  border: none;
  text-align: center;
  margin: auto;
}
.mrgn {
  margin-top: 150px !important;
}
.yellw {
  background-color: #fbd83f;
}
.clrg {
  color: rgba(0, 0, 0, 0.651) !important;
}
.yellw1 {
  color: #fbd83f;
}
.borderrad {
  border: none;
}
.border5 {
  border-radius: 10px;
  border: 1px solid black;
}
.border1 {
  border-radius: 40px;
  border: 1px solid black;
}
.grndclr {
  color: #b40821 !important;
}
.fntfamil {
  font-family: "adobe-clean", sans-serif;
}
.rdi {
  border-radius: 20px;
}
.boldt {
  font-weight: bolder;
  /* color: black; */
}

.rdiu {
  border-top-right-radius: 50px;
  border-bottom-left-radius: 50px;
}
.rdiu2 {
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
  background-color: #fbd83f;
}

.sty {
  list-style-type: none;
}
.boldbtn {
  font-weight: 600;
}
.medis {
  position: absolute;
  top: 62%;
  left: 4%;
}

.medis1 {
  border: 1px solid white;
  border-top-left-radius: 30px;
  border-bottom-right-radius: 30px;
  border-bottom-left-radius: 100px;
}
.firstcart {
  position: relative;
  bottom: 30%;
  right: 7%;
  /* border-bottom-right-radius: 140px; */
}

.imgs {
  width: 150px;
  height: 150px;
}
.drop_dow {
  position: absolute;
  height: 40vh;
  overflow-y: scroll;
  z-index: 100;
}
.p {
  margin-bottom: 0 !important;
  margin-top: 0 !important;
}
.mtchan {
  margin-top: -30px;
}
.drop_dow::-webkit-scrollbar {
  display: none;
}
.modal_header {
  background-color: #b40821 !important;
}
.linkt {
  text-decoration: none !important;
  color: black;
}
@media only screen and (max-width: 800px) {
  .medis1 {
    width: 180px;
    position: static;
  }
  .medis2 {
    width: 100px;
  }
  .img {
    width: 40px;
    height: 40px;
  }
  .btn {
    width: 120px;
    /* height: 30px; */
  }
  .textsi {
    font-size: 10px;
  }
}

@media only screen and (min-width: 700px) and (max-width: 1000px) {
  .medis4 {
    width: 150px;
    position: static;
  }
  .medis3 {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .inputd {
    width: 100%;
  }
  .inputbox {
    height: 10vh;
  }
  .inputbox {
    margin-left: 10px;
  }
  .medis2 {
    width: 80px;
  }
  .imgsub {
    width: 100px;
    height: 50px;
  }

  .imgd {
    width: 45px;
    height: 45px;
  }

  .btn {
    width: 100px;
    font-size: 10px;
    margin: auto;
  }

  .textsi {
    font-size: 8px;
  }
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.navbar-center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  margin: 0 auto;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}


/* Default styles: Hide mobile view, show web view */
.home_mobileview {
  display: none;
}

.home-webview {
  display: block;
}

/* For mobile screens (width less than or equal to 768px) */
@media (max-width: 768px) {
  .home_mobileview {
    display: block;
  }

  .home-webview {
    display: none;
  }
}


.dropdown-menu {
  position: absolute;
  will-change: transform;
  z-index: 1050; /* Ensure it stays above other elements */
  min-width: 150px; /* Adjust if items appear too narrow */
  overflow: visible; /* Prevent clipping */
}


.city-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  flex-wrap: wrap; /* Ensure that items wrap when needed */
  gap: 2px; /* Adjust the gap between columns, decrease this value as needed */
}

.city-item {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  align-items: center; /* Center-align content */
  text-align: center; /* Center-align text */
  margin: 0 5px; 
  
}

.city-image {
  width: 40%; /* Adjust image width */
  height: auto; /* Maintain aspect ratio */
  max-width: 150px; /* Optional: Limit max width */
  border-radius: 8px; /* Optional: Rounded corners */
  text-align: center; /* Center-align text */
}

.city-name {
  margin-top: 0.2rem; /* Add space between image and text */
  font-size: 1.2rem; /* Adjust font size */
  color: #333; /* Text color */
  text-align: center !important; /* Center-align text */
}

.city-name.active {
  font-weight: bold;
  color: #007bff; /* Highlight color for active city */
}

.web-city-modal, .web-city-modal * {
  border: none !important;
}
.mobile-city-modal, .mobile-city-modal * {
  border: none !important;
}
.city-grid.mobile-view {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Three equal columns */

  justify-items: center; /* Center each city item */
}

.city-item {
  display: flex;
  flex-direction: column; /* Stack image and text vertically */
  align-items: center; /* Center-align content */
  text-align: center;
  padding: 2px !important;
  margin: 2px !important;
}
