.faqs {
  padding: 80px 0;
  background-color: #71859e05;
  .title {
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    color: #b40821;
    margin-bottom: 20px;
  }
  .heading {
    font-weight: 700;
    font-size: 56px;
    line-height: 66px;
    text-align: center;
    color: #000000;
  }
  .faq_wrapper {
    margin-top: 70px;
    .MuiPaper-root {
      box-shadow: none;
      position: unset;
      padding: 15px 0;
      background: transparent;
      margin: 0;
    }
    .question {
      font-weight: 700;
      font-size: 18px;
      line-height: 26px;
      margin: 0;
    }
  }
}

@media screen and (max-width: 560px) {
  .faqs {
    padding: 30px 0;
    .heading {
      font-size: 26px;
      line-height: 26px;
    }
    .faq_wrapper {
      margin-top: 10px;
      .MuiPaper-root{
        padding: 0;
      }
      .question{
        font-size: 14px;
        font-weight: 400;
      }
      .MuiAccordionDetails-root{
        padding: 0 16px 16px;
        p{
            font-size: 12px;
        }
      }
    }
  }
}
