.booking_summary {
    width: 100%;
    background: #ffffff;
    border-radius: 5px;
    padding: 0px;
      margin-top: 30px;
    .title {
      font-weight: 700;
      font-size: 22px;
      line-height: 26px;
      color: #000000;
      margin-bottom: 0px;
      padding: 20px;
  }
  .summary_points{
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px 20px;
      .key{
          font-weight: 300;
          font-size: 16px;
          line-height: 19px;
          color: #000000;
      }
      .value{
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        text-align: right;
        color: #000000;
      }
      &.red{
        .key{color: #B40821}
        .value{color: #B40821}
      }
      &.otp{
        padding: 15px 20px;
        background: #1DE46110;
        .otp_text{
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
            color: #000000;
            span{
                font-weight: 700;
                font-size: 20px;
                line-height: 24px;
                color: #1DE461;
                margin-left: 10px;
            }
        }
    }
      &.total{
          border-top: 1px solid #71859e33;
          padding: 20px;
          margin-top: 10px;
          .key{
              font-weight: 400;
              font-size: 22px;
              line-height: 26px;
              color: #000000;
          }
          .value{
              font-weight: 700;
              font-size: 22px;
              line-height: 26px;
              color: #000000;
          }
      }
    }
  }
