/* Add font-family: Poppins globally */

  .horizontal-line {
    position: relative;
    width: 100%;
    border-top: 1px solid #e0e0e0;
    margin: 20px auto;
  }
  
  .category-links {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
  }
  
  .category-links h2 {
    font-size: 24px;
    color: #333;
    margin-top: 15px !important;
    font-weight: bold ;
   
  }
  
  .category-links h4 {
    font-size: 18px !important;
    color: #444;
    margin-top: 30px !important;
    font-weight: bold ;
    
  }
  
  .city-links {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding: 0;
    margin-bottom: 10px;
  }
  
  .city-links li {
    list-style-type: none;
    margin-right: 40px;
    position: relative;
    padding-left: 15px; /* Adjust to make space for the bullet */
  }
  
  .city-links li::before {
    content: "•";
    color: orange;
    position: absolute;
    left: 0;
    top: -5px; /* Adjust this value to control the upward shift */
    font-size: 2em; /* Adjust bullet size if needed */
    line-height: 1; /* Align with text vertically */
  }
  
  .city-links li a {
    text-decoration: none;
    color: black;
    transition: color 0.3s ease;
    font-size: 14px;
  
  }
  
  .city-links li a:hover {
    color: #004080;
    text-decoration: underline;
  }
  
  .service-links {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 0;
  }
  
  .service-links li {
    list-style-type: disc;
    list-style-position: inside;
    margin-left: 10px;
    
  }
  
  .service-links li::marker {
    color: #ff6600; /* Orange bullet color */
    font-size: 1.2em;
     line-height: 1.2; /* Adjust this value to move the dot downwards */
    

  }
  
  .service-links li a {
    text-decoration: none;
    color: #333;
    transition: color 0.3s ease;
    font-size: 14px;
    
  }
  
  .service-links li a:hover {
    color: #0066cc;
    text-decoration: underline;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .city-links {
      flex-direction: column;
    }
  
    .city-links li {
      margin-bottom: 10px;
    }
  }
  