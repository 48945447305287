.offwhite-circle {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #9e0c22; /* Offwhite color */
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0 auto; /* Center the circle horizontally */
  }
  
  .circle-content {
    font-size: 10px;
    color: whitesmoke;
  }

  .footer-icon {
    color: grey;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .footer-icon:hover {
    color: maroon;
  }
  
  .fixed-footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: aliceblue; /* Ensure the background color matches the footer */
    z-index: 1000; /* Ensure it stays above other content */
  }
  
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }
  
  .pulse-icon {
    animation: pulse 1.0s infinite;
  }
  
  