
  /* for floor polish*/
/* Navigation buttons */

.swiper-button-next-floorpolish,
.swiper-button-prev-floorpolish {
  color: #fff !important; /* White arrow color */
  font-size: 24px; /* Size of arrows */
  position: absolute;
  font-size: 16px; /* Set font size */
  margin-top: -20px; /* Move the arrows upwards */
  top: 50%; /* Position vertically in the middle of the image */
  transform: translateY(-50%); /* Center vertically */
  z-index: 10; /* Ensure the arrows appear above the swiper content */
  background-color: #333 !important; /* Dark grey background */
  width: 25px; /* Set a fixed width for a perfect circle */
  height: 25px; /* Set a fixed height for a perfect circle */
  display: flex; /* Use flex to center arrow icon */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  border-radius: 50%; /* Make button circular */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2) !important; /* Optional: Add a slight shadow */
  cursor: pointer; /* Change cursor to pointer on hover */
}

/* Hover effect for navigation buttons */
.swiper-button-next-floorpolish:hover,
.swiper-button-prev-floorpolish:hover {
  background-color: #444 !important; /* Slightly lighter grey on hover */
}

/* Next button position */
.swiper-button-next-floorpolish {
  right: -5px; /* Position next button to the right */
}

/* Previous button position */
.swiper-button-prev-floorpolish {
  left: -5px; /* Position previous button to the left */
}

/* Pagination container */
.swiper-pagination-floorpolish {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
}

/* Optional: Adjust font size of subcategory */
.poppins-medium {
  font-size: 14px; /* Slightly smaller font size */
}


/*For Home repair*/
.swiper-button-next-homerepair,
.swiper-button-prev-homerepair {
  color: #fff !important; /* White arrow color */
  font-size: 24px; /* Size of arrows */
  position: absolute;
  font-size: 16px; /* Set font size */
  margin-top: -20px; /* Move the arrows upwards */
  top: 50%; /* Position vertically in the middle of the image */
  transform: translateY(-50%); /* Center vertically */
  z-index: 10; /* Ensure the arrows appear above the swiper content */
  background-color: #333 !important; /* Dark grey background */
  width: 25px; /* Set a fixed width for a perfect circle */
  height: 25px; /* Set a fixed height for a perfect circle */
  display: flex; /* Use flex to center arrow icon */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  border-radius: 50%; /* Make button circular */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2) !important; /* Optional: Add a slight shadow */
  cursor: pointer; /* Change cursor to pointer on hover */
}

/* Hover effect for navigation buttons */
.swiper-button-next-homerepair:hover,
.swiper-button-prev-homerepair:hover {
  background-color: #444 !important; /* Slightly lighter grey on hover */
}

/* Next button position */
.swiper-button-next-homerepair {
  right: -5px; /* Position next button to the right */
}

/* Previous button position */
.swiper-button-prev-homerepair {
  left: -5px; /* Position previous button to the left */
}

/* Pagination container */
.swiper-pagination-homerepair {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
}

/*Responsive for cleaning*/
/* services.css */

/* Main title styles */
.service-section {
    text-align: left;
    font-size: 24px; /* Font size for the main title */
}

/* Responsive styles for the title span */
.service-section span {
    background-color: #FFB6C1;
    padding: 5px 40px; /* Adjusted padding */
    border-radius: 20px;
    font-size: 18px; /* Font size for the discount text */
    font-weight: bold; /* Emphasized text */
    margin-left: 10px; /* Adjust the gap size here */
}

/* Row styles */
.row {
    margin-top: 1rem; /* Margin top for the row */
}

/* Column styles */
.col-4 {
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Image styles */
.col-4 img {
    width: 80px; /* Adjusted for consistent sizing */
    height: 80px;
    border-radius: 10px;
    margin-top: 0.5rem; /* Margin top for images */
}

/* Subcategory text styles */
.poppins-light {
    text-align: center;
    font-size: 14px; /* Font size for subcategory text */
}

/* Button styles */
#view-all-cleaning {
    width: 80px; /* Square shape */
    height: 80px; /* Square shape */
    padding: 0; /* Matches alignment with row elements */
    border-radius: 0; /* Square shape with zero radius */
    background-color: #b0c4de; /* Greyish-blue shade */
    color: white;
    border: none;
    cursor: pointer;
    text-align: center;
    font-size: 12px; /* Text size for button */
    font-weight: bold; /* Emphasized text */
    position: relative; /* For inner circle positioning */
    display: flex; /* Ensure flex for alignment */
    justify-content: center; /* Center text and inner circle */
    align-items: center; /* Center text and inner circle */
}

/* Circle styles inside the button */
#view-all-cleaning span {
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Circle styles */
#view-all-cleaning div {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: white;
    color: #b0c4de;
    font-size: 12px; /* Font size for circle text */
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px; /* Add space between text and circle */
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
    .service-section {
        font-size: 20px; /* Smaller font size for mobile */
    }

    .service-section span {
        font-size: 16px; /* Smaller font size for mobile */
        padding: 5px 20px; /* Adjusted padding for mobile */
    }

    .col-4 img {
        width: 60px; /* Smaller image size for mobile */
        height: 60px;
    }

    .poppins-light {
        font-size: 12px; /* Smaller font size for subcategory text */
    }

    #view-all-cleaning {
        width: 70px; /* Smaller button size for mobile */
        height: 70px; /* Smaller button size for mobile */
        font-size: 10px; /* Smaller font size for button */
    }

    #view-all-cleaning div {
        width: 18px; /* Smaller circle size */
        height: 18px; /* Smaller circle size */
        font-size: 10px; /* Smaller font size for circle text */
    }
}


/*Title and highlights */


.all-service {
    display: flex; /* Use flexbox to align items in a row */
    align-items: center; /* Center items vertically */
    margin-top: 15px; /* Top margin for the title */
  }
  
  .service-title {
    font-size: 24px; /* Font size for the title */
    font-weight: bold; /* Semi-bold font weight */
    margin: 0; /* Remove default margin */
  }
  
  .highlighted-text {
    padding: 5px; /* Padding for the highlighted text */
    border-radius: 20px; /* Round edges */
    font-size: 18px; /* Font size for the highlighted text */
    font-weight: bold; /* Bold font weight */
    margin-left: 10px; /* Space between title and highlighted text */
    flex-grow: 1; /* Allow the highlighted text to grow and fill available space */
    max-width: calc(100% - 150px); /* Prevent it from taking too much space */
    text-align: center; /* Center the text inside the highlighted area */
    white-space: nowrap; /* Prevent text from wrapping */
  }
  
  /* Media Queries for Responsiveness */
  @media (max-width: 768px) {
    .service-title {
      font-size: 20px; /* Smaller font size for smaller screens */
    }
  
    .highlighted-text {
      font-size: 16px; /* Smaller font size for highlighted text */
    }
  }
  
  @media (max-width: 576px) {
    .service-title {
      font-size: 18px; /* Smaller font size for very small screens */
    }
  
    .highlighted-text {
      font-size: 14px; /* Smaller font size for highlighted text */
  }
  }
  

 
  
  

  
  
  .background-container {
    position: relative;
    width: 100%;
    height: 100vh; /* Use fixed viewport height */
   
    background-size: 100% 100%; /* Make sure it stays fixed, no scaling */
    background-position: center top;
    background-repeat: no-repeat;
  }
  
  .background-video {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%); /* Center video horizontally */
    max-width: 100%;
    width: auto ;
    height: auto; /* Fix the height and width, no scaling */
    object-fit: cover; /* Make sure the video fills the container */
    z-index: -1;
   

  }
  
  
  
  /* Media query for device size 360px width and 740px height */
  @media (min-width: 359px)  {
    .most_trending {
      margin-top: 200px ; /* Reduced margin for smaller devices */
      margin-bottom:10px;
    }
  }
   /* Media query for device size 360px width and 740px height */
   @media (min-width: 389px) {
    .most_trending {
      margin-top: 255px !important; /* Reduced margin for smaller devices */
      margin-bottom:10px;
    }
  }
  
  @media (min-width: 383px) {
    .most_trending {
      margin-top: 210px !important ; /* Reduced margin for smaller devices */
      margin-bottom:10px;
    }
  }
    /* Media query for device size 360px width and 740px height */
    @media (min-width: 429px) {
      .most_trending {
        margin-top: 250px !important; /* Reduced margin for smaller devices */
        margin-bottom:10px;
      }
    }
    
    /* Media query for device size 360px width and 740px height */
    @media (min-width: 360px) {
      .most_trending {
        margin-top: 190px ; /* Reduced margin for smaller devices */
        margin-bottom:10px;
      }
    }
  
    @media (min-width: 393px) {
      .most_trending {
        margin-top: 230px ; /* Reduced margin for smaller devices */
        margin-bottom:15px;
      }
    }

  .content-container {
    width: 100%; /* Ensure the container takes the full width of the device */
    padding: 10px; /* Add padding to the content container */
    box-sizing: border-box; /* Ensure padding does not affect the width */
  }
  

  @media (max-width: 320px) {
    .search-icon {
      margin-top: -20px !important; /* Shift upwards by 10px */
       

    }
    .glass-icon {
      margin-left: -50px,

    }
    
    .font-16 {
      font-size: 12px;
    }

  }

  @media (max-width: 335px) {
    .search-icon {
      margin-top: -10px !important; /* Shift upwards by 10px */  

    }
    
  }

  @media (max-width: 360px) {
    .search-icon {
      margin-top: -20px !important; /* Shift upwards by 10px */ 
      padding-top: -20px !important; /* Shift upwards by 10px */


    }
    .background-container {
      padding-top: -20px !important ; /* Adjust padding as needed */
    }
  }
  @media (min-width: 375px) {
    .search-icon {
      margin-top: -10px !important; /* Shift upwards by 10px */  

    }
    
  }

  @media (min-width: 414px) {
    .search-icon {
      margin-top: 5px !important; /* Shift upwards by 10px */  

    }
    
  }

  @media (max-width: 412px) {
    .search-icon {
       
padding-top: 20px !important;
    }
    
  }
  @media (min-width: 480px) {
    .search-icon {
      margin-top: 5px !important; /* Shift upwards by 10px */  

    }
    
  }
  @media (min-width: 720px) {
    .search-icon {
      margin-top: 10px !important; /* Shift upwards by 10px */  

    }
    
  }

  @media (min-width: 390px) {
    .search-icon {
      margin-top: 50px !important; /* Shift upwards by 10px */ 
      padding-top: 10px;

    }
    
  }

  @media (min-width: 393px) {
    .search-icon {
      margin-top: 2px !important; /* Shift upwards by 10px */  

    }
    
  }
  @media (min-width: 363px) {
    .search-icon {
      margin-top: -20px !important; /* Shift upwards by 10px */  

    }
    
  }

  @media (min-width: 414px) {
    .search-icon {
      margin-top: 4px !important; /* Shift upwards by 10px */  

    }
    
  }
  @media (min-width: 390px) {
    .container-padding {
      margin-top: 80px !important; /* Shift upwards by 10px */
      padding-top: 80px !important;
    }
  }
  
  @media (min-width: 391px) {
    .container-padding {
      margin-top: 20px !important; /* Shift downwards by 20px */
    }
  }

  
  @media (max-width: 412px) {
    .category-container-downwards {
      padding-top: 6px !important; /* Shift upwards by 10px */
    }
  }
 
  @media (max-width: 375px) {
    .category-container-downwards {
      padding-top: 6px !important; /* Shift upwards by 10px */
    }
  }
  @media (min-width: 430px) {
    .container-padding {
      margin-top: 60px !important; /* Shift downwards by 20px */
      padding-top: 45px !important; /* Shift downwards by 20px */
    }
  }
  
  @media (max-width: 360px) {
    .container-category {
      margin-top: 40px !important; /* Shift upwards by 10px */ 
      padding-top: 10px !important; /* Shift upwards by 10px */


    }}

    @media (max-width: 393px) {
      .container-category {
        margin-top: 40px !important; 
      
  
  
      }}
    /* Add new query for 390px */
@media (min-width: 390px) {
  .container-category {
    margin-top: 60px !important; /* Move down */
    padding-top: 5px !important; /* Additional downward spacing */
  }
}
@media (max-width: 414px) {
  .container-category {
    margin-top: 10px !important; /* Move down */
    
  }
}

@media (min-width: 412px) {
  .container-category {
    margin-top: 35px !important; /* Move down */
  }
}
    @media (min-width: 360px) {
      .glass-icon {
       margin-left: -30px !important; /* Shift upwards by 10px */
      
       
  
      }}
      @media (max-width: 360px) {
        .category-container-downwards {
          padding-top: 5px !important; /* Shift upwards by 10px */
        }
      }
   

    

    @media (max-width: 414px) {
      .container-padding {
        margin-top: 50px !important; /* Shift downwards by 20px */
      padding-top: 120px !important;
      }
    }
    

    @media (min-width: 350px) {
      .container-padding {
       
      padding-top: 0px !important;
      }
    }
    @media (min-width: 360px) {
      .container-padding {
        padding-top: 10px !important;
      }
    }
    .container-padding {
      margin-top: 20px !important; /* Shift downwards by 20px */
      padding-top: 40px;
    }


    @media (min-width: 430px) {
      .container-padding {
        margin-top: 20px !important; /* Shift downwards by 20px */
        padding-top: 40px;
      
      }
    }

    @media (min-width: 383px) {
      .container-padding {
        margin-top: 20px !important; /* Shift downwards by 20px */
        padding-top: 20px;
      
      }
    }


    
    @media (min-width: 412px) {
      .container-padding {
        margin-top: 30px !important; /* Shift downwards by 20px */
      padding-top: 30px !important;
      }
    }
    
   
    
      @media (min-width: 360px) {
        .container-category {
         
          padding-top: -45px !important; /* Shift upwards by 10px */
          
    
        }}
      
      @media (min-width: 430px) {
        .container-category {
          margin-top: 20px !important; /* Shift upwards by 10px */ 
         
    
        }}
        @media (min-width: 393px) {
          .container-padding {
            margin-top: 20px !important; /* Shift downwards by 20px */
            padding-top: 10px !important;
          
          }
        }
    
        @media (min-width: 390px) {
          .container-padding {
            margin-top: 10px !important; /* Shift downwards by 20px */
            padding-top: 30px;
          
          }
        }

        @media (min-width: 425px) {
          .container-padding {
            margin-top: 20px !important; /* Shift downwards by 20px */
            padding-top: 20px;
          
          }
        }

        @media (min-width: 360px) {
          .background-container {
         
            width: 100%;
            height: 100%;
          }
          .category_img {
            height: 60px !important;
            width: 60px !important;
          }
        }

        @media (min-width: 430px) {
          .background-container {
          margin-top: 5px;
          }
          
        }
        @media (min-width: 359px) {
          .background-container {
          margin-top: -5px;
          }
          
        }


    
        
        .background-video {
          width: 100%;
          height: auto;
        }
        .overlay-gif {
          position: absolute; /* VERY IMPORTANT - needed for absolute positioning */
          top: 70%;         /* Set your desired top position for smaller screens */
          left: 50%;        /* Center horizontally for smaller screens */
          transform: translate(-50%, -50%); /* Center it precisely */
          
          
          opacity: 1;
          margin-top: 0;     /* Reset base margin-top */
      }
      
        
        @media (min-width: 363px)  {
          .overlay-gif {
            margin-top: 90px ; /* Reduced margin for smaller devices */
            margin-bottom:20px !important;
          }
        }
        @media (min-width: 430px) {
          .overlay-gif {
              top: 50%;         /* Adjusted top for 430px and wider */
              margin-top: 130px !important; /* Adjusted margin-top for 430px and wider */
          }
      }

        @media (min-width: 360px)  {
          .overlay-gif {
            margin-top: 90px ; /* Reduced margin for smaller devices */
            margin-bottom: 10px ;
          }
        }


/* Styles for screens up to 412px width (and narrower) */
@media (max-width: 412px) {
  .overlay-gif {
    margin-top: 110px
  }
}