.testimonial {
  background-color: #fff;
  margin-top: 55px;
  padding-bottom: 50px;
  .trust {
    width: "100%";
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    color: #b40821;
    margin-bottom: 15px;
  }
  .main_heading {
    margin-bottom: 30px;
  }
  .testi_wrapper {
    position: relative;
    &::after{
        content: "";
        position: absolute;
        right: 0;
        top:0px;
        height: calc(100% );
        width: 30%;
        background: linear-gradient(270deg, #fffffffa 24.23%, rgba(255, 255, 255, 0) 100%);
        z-index: 1;
    }
    &::before{
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 30%;
        background: linear-gradient(90deg, #fffffffa 29.23%, rgba(255, 255, 255, 0) 100%);
        z-index: 2;
    }
    .testi_box {
      margin: 0 10px;
      .video {
        width: 100%;
        border-radius: 10px;
        overflow: hidden;
        position: relative;
        .thumbnail {
          border-radius: 10px;
          width: 100%;
          height: 200px;
          margin-bottom: 15px;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .ply_btn {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          img {
            width: 50px;
            height: 50px;
          }
        }
      }
      .content {
        text-align: center;
      }
      .name {
        font-weight: 700;
        font-size: 26px;
        margin: 10px 0 5px;
        line-height: 26px;
        text-align: center;
        color: #000000;
      }
      .service {
        text-align: center;
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        text-align: center;
        color: #b40821;
      }
    }
  }
}


@media screen and (max-width: 560px) {
  .testimonial{
    padding-bottom: 20px;
    .testi_wrapper{
      &::after, &::before{
        display: none;
      }
      .testi_box{
        .video{
          .thumbnail{
            overflow: hidden;
            height: 150px;
            width: 100%;
          }
        }
        .content{
          font-size: 14px;
        }
        .name{
          font-size: 16px;
          line-height: 20px;
          margin-bottom: 0;
        }
        .service{
          font-size: 14px;
        }
      }
    }
  }
}