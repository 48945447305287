.reset_password {
    background-color: #fff;
    .page_heading {
      height: 30vh;
      background-color: #000000d1;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      background-image: url("./../../Assets/Images/hero_back.jpg");
      background-blend-mode: overlay;
      .title {
        margin-top: 130px;
        font-weight: 700;
        font-size: 40px;
        line-height: 48px;
        text-align: center;
        letter-spacing: 2px;
        text-transform: capitalize;
        color: #ffffff;
      }
    }
    .reset_wrapper {
      max-width: 400px;
      width: 100%;
      margin: 80px auto;
      background-color: #f8f9fa;
      border-radius: 5px;
      .login_header {
        padding: 25px;
        border-bottom: 1px solid #71859e36;
        .title {
          font-weight: 700;
          font-size: 22px;
          line-height: 26px;
          color: #000000;
        }
      }
      .login_body {
        padding: 25px;
        button {
          background: #b40821;
          border-radius: 8px;
          color: #fff;
          font-weight: 600;
          font-size: 18px;
          line-height: 19px;
          text-align: center;
          text-transform: capitalize;
          color: #ffffff;
          border: 0;
          width: 100%;
          padding: 18px;
          margin-top: 8px;
        }
      }
    }
  }


  @media (min-width: 992px) and (max-width: 1240px) {
    .reset_password {
      .page_heading {
        height: 35vh;
        .title {
          margin-top: 180px;
        }
      }
    }
  }
  @media screen and (max-width: 560px) {
    .reset_password {
      .reset_wrapper {
        margin: 20px auto;
      }
      .page_heading {
        height: 20vh;
        .title {
          margin-top: 75px;
          font-size: 26px;
        }
      }
    }
  }