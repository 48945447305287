.scheduleservice {
  // margin-top: 30px;
  // background-color: #fff;
  // border-radius: 5px;
  // border: 1px solid #71859e33;
  .title {
    // padding: 25px;
    // border-bottom: 1px solid #71859e33;
    font-weight: 700;
    font-size: 22px;
    line-height: 26px;
  }
  .select_date {
    padding: 25px;
    .text {
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      display: flex;
      align-items: center;
      color: #000000;
      margin-bottom: 25px;
    }
    .date_selection {
      label {
        input {
          display: none;
          & + span {
            background: #fff;
            text-transform: capitalize;
            border-radius: 8px;
            color: #000;
            min-width: 125px;
            text-align: center;
            padding: 10px 22px;
            margin-right: 20px;
            transition: 0.3s;
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            display: block;
            margin-bottom: 15px;
            border: 1px solid rgba(113, 133, 158, 0.5);
          }
          &:checked {
            & + span {
              background: #03b162;
              color: #fff;
            }
          }
        }
      }
    }
    .date {
      margin-top: 5px;
      button {
        background-color: #fff;
        border: 1px solid #000;
        color: #000;
        border-radius: 8px;
        padding: 15px 20px;
        border: 1px solid rgba(113, 133, 158, 0.5);
        img {
          margin-right: 10px;
        }
      }
    }
  }
}
