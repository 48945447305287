
.addoncontr::-webkit-scrollbar {
  display: none;
}
.addon_box {
  background-color: #71859e20;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 25px;

  .addon_image {
    width: 100%;
    max-height: 130px;
    height: 100%;
    position: relative;
    overflow: hidden;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
    .offer {
      position: absolute;
      top: 20px;
      right: 0;
      background-color: #000;
      font-weight: 800;
      font-size: 14px;
      line-height: 127.34%;
      color: #ffffff;
      border-radius: 5px 0px 0px 5px;
      padding: 12px 15px;
    }
  }
  .addon_details {
    padding: 18px 15px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    .left {
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
      flex-direction: column;
      .title {
        font-weight: 600;
        font-size: 18px;
        line-height: 21px;
        color: #000000;
        margin-bottom: 8px;
      }
      .price {
        font-weight: 800;
        font-size: 22.8955px;
        line-height: 27px;
        color: #03b162;
      }
      .fake_prices {
        font-weight: 400;
        font-size: 18px;

        line-height: 21px;
        text-decoration-line: line-through;
        color: #71859e;
      }
    }
    .right {
      button {
        background: #03b162;
        border-radius: 5px;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #ffffff;
        padding: 12px 20px;
        img {
          width: 14px;
          margin-right: 8px;
        }
      }
    }
  }
}
