.summary {
  width: 100%;
  background: #ffffff;
  border-radius: 5px;
  padding: 0px;
  margin-top: 30px;
  .title {
    font-weight: 700;
    font-size: 22px;
    line-height: 26px;
    color: #000000;
    margin-bottom: 0px;
    padding: 20px;
  }
  .summary_points {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 20px;
    .key,
    .value {
      font-weight: 300;
      font-size: 16px;
      line-height: 19px;
      color: #000000;
    }
    &.total {
      border-top: 2px dashed #71859e33;
      padding: 20px;
      margin-top: 10px;
      .key {
        font-weight: 400;
        font-size: 22px;
        line-height: 26px;
        color: #000000;
      }
      .value {
        font-weight: 700;
        font-size: 22px;
        line-height: 26px;
        color: #000000;
      }
    }
  }
}

@media screen and (max-width: 560px) {
  .summary {
    margin-bottom: 20px;
  }
}
