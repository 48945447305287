.booking_detail {
  background-color: #71859e0f;
  padding: 50px 0;
  .tabs {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    button {
      margin-right: 20px;
      background: #ffffff;
      border: 1px solid rgba(113, 133, 158, 0.5);
      border-radius: 8px;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      text-transform: capitalize;
      color: #000000;
      padding: 15px 30px;
      width: max-content;
      min-width: 130px;
      &.active {
        background: #03b162;
        border: 1px solid rgba(113, 133, 158, 0.5);
        color: #ffffff;
      }
    }
  }
  .tab_view {
    background-color: #fff;
    border-radius: 5px;
    margin-top: 50px;
    cursor: pointer;
    .tab_heading {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #71859e20;
      padding: 20px;
      .left {
        .book_id {
          font-weight: 700;
          font-size: 18px;
          line-height: 21px;
          color: #000000;
          margin-bottom: 8px;
        }
        .book_date {
          font-weight: 600;
          font-size: 15px;
          line-height: 18px;
          color: #71859e;
        }
      }
      .right {
        .tab_price {
          font-weight: 700;
          font-size: 22px;
          line-height: 26px;
          color: #000000;
        }
      }
    }
    .services_time {
      background-color: #71859e0f;
      padding: 20px;
      .s_time_title {
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        margin-bottom: 8px;
        color: #71859e;
      }
      .s_time {
        font-weight: 700;
        font-size: 22px;
        line-height: 26px;
        color: #000000;
        span {
          color: #03b162;
        }
      }
    }
    .tab_ser_detail {
      padding: 20px;
      .title {
        font-weight: 700;
        font-size: 20px;
        line-height: 127.34%;
        color: #000000;
        padding-bottom: 20px;
      }
      .tab_ser {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 15px;
        &:last-child {
          margin-bottom: 0;
        }
        .tab_img {
          height: 60px;
          width: 60px;
          overflow: hidden;
          border-radius: 5px;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .ser_text {
          margin-left: 15px;
          h4 {
            margin: 0;
            font-weight: 500;
            font-size: 18px;
            line-height: 21px;
            display: flex;
            align-items: center;
            color: #000000;
            margin-bottom: 8px;
          }
          p {
            margin: 0;
            font-weight: 500;
            font-size: 18px;
            line-height: 21px;
            display: flex;
            align-items: center;
            color: #71859e;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .booking_detail {
    .tabs {
      button {
        width: 100%;
        min-width: max-content;
        margin: 0;
      }
      button + button {
        margin: 0 0 0 16px;
      }
    }
  }
}
@media screen and (max-width: 560px) {
  .booking_detail {
    .tabs {
      button {
        padding: 10px 15px;
      }
    }
    .tab_view {
      .tab_heading {
        flex-direction: column;
        align-items: flex-start;
        .right{
          margin-top: 15px;
          border-top: 1px solid #00000010;
          width: 100%;
          padding-top: 10px;
      }
    }
    }
  }
}
